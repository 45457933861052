import React from 'react'
import {
    Avatar as ChakraAvatar,
    AvatarProps as ChakraAvatarProps,
    Skeleton,
} from '@chakra-ui/react'
import { useMultiStyleConfig } from '@chakra-ui/system'
import Link from 'next/link'
import { ImgixParams, generateSrcSet, toImgixUrl } from 'lib/imgix'

const LinkWrappedAvatar = ({
    href,
    ...avatarProps
}: {
    href?: string
} & AvatarProps) => {
    return href ? (
        <Link href={href} passHref>
            <ChakraAvatar as="a" {...avatarProps} />
        </Link>
    ) : (
        <ChakraAvatar {...avatarProps} />
    )
}

export const defaultImgixParams = {
    fit: 'clip',
    crop: 'center',
    auto: 'format',
    q: 95,
} as ImgixParams

function parseNumber(s?: any) {
    return parseInt(s) || undefined
}

type AvatarProps = ChakraAvatarProps & {
    imgix?: ImgixParams
    isLoaded?: boolean
    href?: string
}

export const Avatar = (props: AvatarProps) => {
    const { src, imgix, isLoaded = true, href, sx, ...propsRest } = props

    // NB: Supports all 3 ways of passing `width`/`height` in Chakra:
    //     via their full names, via short names, and via `boxSize`,
    //     but ONLY SUPPORTS exact numeric values, e.g. `40`, `40px`.
    const params = {
        w: parseNumber(props.w || props.width || props.boxSize),
        h: parseNumber(props.h || props.height || props.boxSize),
        ...(imgix || defaultImgixParams),
    }

    const imgixSrc = src && toImgixUrl(src, params)

    // NB: This fixes an issue in Chakra's `Avatar` implementation.
    const styles = useMultiStyleConfig('Avatar', { ...propsRest })
    const { borderRadius } = styles.container

    const objectFit = props.variant === 'company' ? 'contain' : 'cover'
    const updatedSX = {
        ...sx,
        '& img': { objectFit, borderRadius },
    }

    return (
        <Skeleton
            isLoaded={isLoaded}
            // NB: Get parent `div` out of the way when is loaded.
            display={isLoaded ? 'contents' : 'block'}
            w="fit-content"
            // @ts-ignore
            borderRadius={borderRadius}
        >
            <LinkWrappedAvatar
                href={href}
                src={imgixSrc}
                srcSet={generateSrcSet(imgixSrc, params)}
                sx={updatedSX}
                {...propsRest}
            />
        </Skeleton>
    )
}
