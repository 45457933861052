import { ConversationId } from 'containers/conversations/types'

export type ConversationUnreadStatus = {
    convId: ConversationId
    unread: number
}

export type UnreadStatus = {
    total: number
    conversations: ConversationUnreadStatus[]
}

export const EmptyUnreadStatus = {
    total: 0,
    conversations: [],
}

type GetUnreadStatus = (
    unreadStatus: UnreadStatus,
    convId?: ConversationId,
) => ConversationUnreadStatus | null

export const getUnreadStatus: GetUnreadStatus = (unreadStatus, convId) => {
    return convId
        ? unreadStatus.conversations?.find(us => us.convId === convId) || null
        : null
}

export const clearUnreadStatus = (unreadStatus: UnreadStatus, convId: ConversationId) => {
    const convUnreadStatus = getUnreadStatus(unreadStatus, convId)
    if (convUnreadStatus) {
        return {
            total: unreadStatus.total - convUnreadStatus.unread,
            conversations: unreadStatus.conversations.filter(us => us.convId !== convId),
        }
    } else {
        return unreadStatus
    }
}

export type ReadReceipt = {
    convId: ConversationId
    lastRead: string
}
