import {
    AblyMessageCallback,
    useChannel as originalUseChannel,
} from '@ably-labs/react-hooks'
import { isServerSide } from 'lib/env'

export default function useChannel(
    channelName: string,
    callbackOnMessage: AblyMessageCallback,
    eventName?: string,
    pause?: boolean,
) {
    if (eventName === '') {
        console.warn(
            `IMPORTANT: Ably doesn't support '' (empty string) as an event name
             for subscription but you have passed it. Please, check client code.`,
        )
    }

    if (!isServerSide && !pause) {
        if (eventName) {
            return originalUseChannel(channelName, eventName, callbackOnMessage)
        } else {
            return originalUseChannel(channelName, callbackOnMessage)
        }
    }
}
