import React, {
    ReactNode,
    SetStateAction,
    createContext,
    useCallback,
    useContext,
    useMemo,
    useRef,
    useState,
} from 'react'
import { useRouter } from 'next/router'
import { configureAbly } from '@ably-labs/react-hooks'
import { getEnv } from 'config'
import { EmptyUnreadStatus, ReadReceipt, UnreadStatus } from 'entities/liveUpdates'
import { routes } from 'routes'
import { isServerSide } from 'lib/env'
import { StateDispatcher } from 'lib/react'
import useChannel from 'hooks/useChannel'
import useMe from 'hooks/useMe'

const useParams = () => {
    const router = useRouter()

    const convId = router.query['convId']

    return React.useMemo(() => {
        return convId && !Array.isArray(convId) ? { convId } : {}
    }, [convId])
}

const useActiveConversationId = () => {
    const { convId } = useParams()
    return convId
}

// LiveUpdates (an umbrella event)

export type LiveUpdates = {
    unreadStatus?: UnreadStatus
    readReceipt?: ReadReceipt
    // onlineStatus?: OnlineStatus // this is an example of the future extension for [FEATURE-249]
}

export const LiveUpdatesContext = createContext<{
    liveUpdates: LiveUpdates
    setLiveUpdates: StateDispatcher<LiveUpdates>
} | null>(null)

const updateLiveUpdates = (
    setLiveUpdates?: StateDispatcher<LiveUpdates>,
    newLiveUpdates?: LiveUpdates,
) => {
    if (setLiveUpdates) {
        setLiveUpdates(liveUpdates => ({ ...liveUpdates, ...newLiveUpdates }))
    }
}

export type MessageCallback<T> = (message: T) => void

// TODO [CU-35ynqv6]: we shouldn't load ably for public users
// TODO [CU-35ynqv6]: we shouldn't load ably for storybook
export const LiveUpdatesProvider = ({ children }: { children: ReactNode }) => {
    const [liveUpdates, setLiveUpdates] = useState<LiveUpdates>({})
    const convId = useActiveConversationId()
    const me = useMe()
    const ablyInstance = useRef(null)

    useMemo(() => {
        if (!isServerSide && me && !ablyInstance.current) {
            ablyInstance.current = configureAbly({
                authUrl: `${getEnv('apiUrl')}${routes.api.updatesToken()}`,
                // NB: Need this to make Ably XHR have the `withCredentials = true`.
                authHeaders: { authorization: '' }, // ...still, no value is needed
                // TODO [FEATURE-249]: This will be necessary for presence checks.
                // clientId: generateRandomId(),
            })
        }
    }, [me, isServerSide])

    const messageListener = useCallback<MessageCallback<any>>(
        message => {
            console.log(`New live update event received: ${message.data}`)
            const payload: LiveUpdates = JSON.parse(message.data)
            updateLiveUpdates(setLiveUpdates, payload)
        },
        [setLiveUpdates],
    )

    // This is a user-specific channel for all sorts of events relevant to a logged-in user.
    // So far it deals solely with `UnreadStatus` updates, but it could be changed later on.
    useChannel(`user-id:${me?.id}`, messageListener, 'unread-status', !me)

    // This is a global shared channel with all read receipts for all conversations/members,
    // thus we are using both IDs to form the event name to filter out only relevant events.
    useChannel('read-receipts', messageListener, `${convId}:${me?.id}`, !me && !convId)

    return (
        <LiveUpdatesContext.Provider value={{ liveUpdates, setLiveUpdates }}>
            {children}
        </LiveUpdatesContext.Provider>
    )
}

// UnreadStatus

type UseUnreadStatus = () => [UnreadStatus, StateDispatcher<UnreadStatus>]

export const useUnreadStatus: UseUnreadStatus = () => {
    const liveUpdatesCtx = useContext(LiveUpdatesContext)
    const unreadStatus = liveUpdatesCtx?.liveUpdates?.unreadStatus || EmptyUnreadStatus
    const setLiveUpdates = liveUpdatesCtx?.setLiveUpdates

    const setUnreadStatus: StateDispatcher<UnreadStatus> = (
        setStateAction: SetStateAction<UnreadStatus>,
    ) => {
        if (typeof setStateAction === 'function') {
            updateLiveUpdates(setLiveUpdates, {
                unreadStatus: setStateAction(unreadStatus),
            })
        } else {
            updateLiveUpdates(setLiveUpdates, {
                unreadStatus: setStateAction,
            })
        }
    }

    return [unreadStatus, setUnreadStatus]
}

// ReadReceipt

type UseReadReceipts = (convId?: string) => ReadReceipt | undefined

export const useReadReceipt: UseReadReceipts = (convId?: string) => {
    const liveUpdatesCtx = useContext(LiveUpdatesContext)
    const readReceipt = liveUpdatesCtx?.liveUpdates?.readReceipt

    if (readReceipt?.convId === convId) {
        return readReceipt
    }
}
