import { Environment, getEnvironment } from 'config'
import qs from 'query-string'

const bucketRegexp = new RegExp('functionalworks-backend--(prod|qa).s3.amazonaws.com')
const isUrlFromProdBucket = (url: string) => url.match(bucketRegexp)?.[1] === 'prod'

const imgixServerProd = 'workshub'
const imgixServerDev = 'workshub-dev'

// https://docs.imgix.com/apis/rendering/size/fit
export type FitOption =
    | 'clamp'
    | 'clip'
    | 'crop'
    | 'facearea'
    | 'fill'
    | 'fillmax'
    | 'max'
    | 'min'
    | 'scale'

// https://docs.imgix.com/apis/rendering/size/crop
export type CropOption =
    | 'center' // the default if no value is set
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'faces'
    | 'focalpoint'
    | 'edges'
    | 'entropy'

// https://docs.imgix.com/apis/rendering/auto/auto
export type AutoOption = 'compress' | 'enhance' | 'format' | 'redeye'

// https://docs.imgix.com/apis/rendering/format/q
export type QualityOption = number // [1 .. 100], default is 75

// https://docs.imgix.com/apis/rendering/size/ar
// NB: Works only when either 'fit=crop' or 'fit=fill' is set.
export type AspectRatioOption = string // 'W:H', e.g. '16:9'

export interface ImgixParams {
    w?: number
    h?: number
    fit?: FitOption
    crop?: CropOption | CropOption[]
    auto?: AutoOption | AutoOption[]
    q?: QualityOption
    ar?: AspectRatioOption
}

/**
 * Builds an Imgix-served URL for the specified image source URL.
 *
 * It the source URL is not from the AWS S3 ('prod' or 'qa') bucket,
 * then it is returned as is. Otherwise, a specific Imgix server is
 * picked up depending on the current execution environment.
 *
 * NB: Although the Next.js provides us with a built-in `loader` for
 *     the Imgix service for its optimized `next/image` component,
 *     we still need this function, because of its complex bucket- &
 *     environment-specific logic of building the resulting URL. And
 *     the built-in 'imgix' loader is not that configurable.
 * https://nextjs.org/docs/api-reference/next/image#built-in-loaders
 *
 * @params url - a source URL of the original image
 * @params params - an optional `ImgixParams` map with Imgix-specific
 *                  set of parameters, including 'fit', 'crop', etc.
 */
export const toImgixUrl_ = (url: string, env: Environment, params?: ImgixParams) => {
    if (!bucketRegexp.test(url)) {
        return url
    }

    const params_ = params ?? { fit: 'crop', crop: 'entropy', auto: ['format'] }

    const url_ = url.split('/')
    const hash = url_[url_.length - 1]

    let imgixServer = imgixServerDev
    if (env === 'prod' || isUrlFromProdBucket(url)) {
        imgixServer = imgixServerProd
    }

    return `https://${imgixServer}.imgix.net/${hash}?${qs.stringify(params_)}`
}

const env = getEnvironment()

export const toImgixUrl = (url: string, params?: ImgixParams) =>
    toImgixUrl_(url, env, params)

/**
 * Read more in the Imgix "Responsive Images with srcset" article:
 * https://docs.imgix.com/tutorials/responsive-images-srcset-imgix
 */
export function generateSrcSet(url?: string, params?: ImgixParams) {
    if (!url || !url.includes('.imgix.net')) {
        return
    }

    const params_ = params ?? { fit: 'crop', crop: 'entropy', auto: ['format'] }
    const { q } = params_

    // Quality can often be set much lower than the default,
    // especially when serving high-DPR images (dpr={2, 3}).
    const params_x1 = qs.stringify(params_)
    const params_x2 = qs.stringify({
        ...params_,
        dpr: 2,
        q: !q ? undefined : q - 10,
    })
    const params_x3 = qs.stringify({
        ...params_,
        dpr: 3,
        q: !q ? undefined : q - 20,
    })

    const url_ = url.substring(0, url.indexOf('?'))

    return [
        `${url_}?${params_x1} 1x`,
        `${url_}?${params_x2} 2x`,
        `${url_}?${params_x3} 3x`,
    ].join(', ')
}
