import React from 'react'
import { Center, Link as ChakraLink, Flex, chakra } from '@chakra-ui/react'
import Link from 'next/link'
import { routes } from 'routes'
import Leaves from 'public/img/leaves.svg'

const LeavesChakra = chakra(Leaves)

const blogPostURL = routes.article({
    id: 'well-plant-a-tree-for-every-job-application-on-our-platform-cd8bb',
})

export const Promo = () => {
    return (
        <Center h="48px" backgroundColor="#DFF2F2" position="relative" zIndex="banner">
            <Flex gridGap="10px" p="4px 8px">
                <LeavesChakra width="34px" height="40px" p="4px 0 2px 0" flexShrink={0} />
                <Link href={blogPostURL} passHref>
                    <ChakraLink
                        variant="hoverUnderline"
                        m="auto"
                        fontWeight={600}
                        fontSize={{ base: '13px', md: '16px' }}
                        lineHeight={{ base: '20px', md: '16px' }}
                        color="text.100"
                    >
                        {`We're planting a tree for every job application! Click here to learn more`}
                    </ChakraLink>
                </Link>
            </Flex>
        </Center>
    )
}
