import { createContext, useContext } from 'react'
import { StandardVertical, defaultVertical } from 'entities/vertical'
import { VerticalConfig, allVerticalsConfig } from 'lib/vertical'

export type VerticalData = {
    vertical: StandardVertical
    verticalConfig: VerticalConfig
    allVerticalUrls: Record<StandardVertical, string>
}

// NB: So to at least end up with relative URLs on a client site.
const defaultAllVerticalUrls: Record<StandardVertical, string> = {
    functional: '',
    blockchain: '',
    javascript: '',
    ai: '',
    golang: '',
    java: '',
    python: '',
    remote: '',
    www: '',
}

// NB: This one misses the `allVerticalUrls` so make sure
//     to wrap your component into the `withVertical` HOC.
const defaultVerticalData = {
    vertical: defaultVertical,
    verticalConfig: allVerticalsConfig[defaultVertical],
    allVerticalUrls: defaultAllVerticalUrls,
}

export const VerticalContext = createContext<VerticalData>(defaultVerticalData)

export default function useVerticalData() {
    return useContext(VerticalContext)
}

export function useVertical() {
    return useContext(VerticalContext).vertical
}

export function useVerticalUrl() {
    const { allVerticalUrls, vertical } = useVerticalData()
    return allVerticalUrls[vertical]
}

export function useVerticalConfig() {
    const { verticalConfig } = useVerticalData()
    return verticalConfig
}
